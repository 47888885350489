const investmentDebt = {
  state: {
    pageType: 'list',
    beginUpdate: new Date(),
    debtId: null
  },
  mutations: {
    set_debt_pageType (state, data) {
      state.pageType = data
    },
    set_debt_update (state, data) {
      state.beginUpdate = data
    },
    set_debt_debtId (state, data) {
      state.debtId = data
    }
  },
  getters: {

  },
  actions: {

  }
}
export default investmentDebt
